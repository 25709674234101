'use strict';

goog.require('Blockly.Python');

Blockly.Python['lookup_function'] = function(block) {
  // Define a procedure with a return value.
  // First, add a 'global' statement for every variable that is not shadowed by
  // a local parameter.
  var globals = [];
  var varName;
  var workspace = block.workspace;
  var variables = Blockly.Variables.allUsedVarModels(workspace) || [];
  for (var i = 0, variable; variable = variables[i]; i++) {
    varName = variable.name;
    if (block.arguments_.indexOf(varName) == -1) {
      globals.push(Blockly.Python.variableDB_.getName(varName,
          Blockly.Variables.NAME_TYPE));
    }
  }
  // Add developer variables.
  var devVarList = Blockly.Variables.allDeveloperVariables(workspace);
  for (var i = 0; i < devVarList.length; i++) {
    globals.push(Blockly.Python.variableDB_.getName(devVarList[i],
        Blockly.Names.DEVELOPER_VARIABLE_TYPE));
  }

  globals = globals.length ?
      Blockly.Python.INDENT + 'global ' + globals.join(', ') + '\n' : '';
  var funcName = Blockly.Python.variableDB_.getName(
      block.getFieldValue('NAME'), Blockly.Procedures.NAME_TYPE);
  var branch = Blockly.Python.statementToCode(block, 'STACK');
  if (Blockly.Python.STATEMENT_PREFIX) {
    var id = block.id.replace(/\$/g, '$$$$');  // Issue 251.
    branch = Blockly.Python.prefixLines(
        Blockly.Python.STATEMENT_PREFIX.replace(
            /%1/g, '\'' + id + '\''), Blockly.Python.INDENT) + branch;
  }
  if (Blockly.Python.INFINITE_LOOP_TRAP) {
    branch = Blockly.Python.INFINITE_LOOP_TRAP.replace(/%1/g,
        '"' + block.id + '"') + branch;
  }
  var returnValue = Blockly.Python.valueToCode(block, 'RETURN',
      Blockly.Python.ORDER_NONE) || '';
  if (returnValue) {
    returnValue = Blockly.Python.INDENT + 'return ' + returnValue + '\n';
  } else if (!branch) {
    branch = Blockly.Python.PASS;
  }
  var args = [];
  for (var i = 0; i < block.arguments_.length; i++) {
    args[i] = Blockly.Python.variableDB_.getName(block.arguments_[i],
        Blockly.Variables.NAME_TYPE);
  }
  var code = 'def ' + funcName + '(' + args.join(', ') + '):\n' +
      globals + branch + returnValue;
  code = Blockly.Python.scrub_(block, code);
  // Add % so as not to collide with helper functions in definitions list.
  Blockly.Python.definitions_['%' + funcName] = code;
  return null;
};

Blockly.Python['block_type'] = function(block) {
  var funcName = 'lookup'
  var LOOKUP_FUNCTION_COMMENT = '\nLookup service\nParams:\n\tsource: string, set to the lookup data source\n\tfield: string, set to the field to lookup\nReturns:\n\tresult: boolean, true if found else false';
  var value_lookup_field = Blockly.Python.valueToCode(block, 'lookup_field', Blockly.Python.ORDER_NONE);
  var value_lookup_source = Blockly.Python.valueToCode(block, 'lookup_source', Blockly.Python.ORDER_NONE);
  // var field = Blockly.Python.quote_(block.getFieldValue('column_name'));
  var code = '""" ' + LOOKUP_FUNCTION_COMMENT + '\n"""\n'
  code = code + 'def '+ funcName +'(field, source):\n'
  code = code + Blockly.Python.INDENT + 'result = false\n'
  code = code + Blockly.Python.INDENT + 'if (!source) and (!field):\n'
  code = code + Blockly.Python.INDENT + Blockly.Python.INDENT + 'service = service_get(source)\n'
  code = code + Blockly.Python.INDENT + Blockly.Python.INDENT + 'service_result = service(field, data[field])\n'
  code = code + Blockly.Python.INDENT + Blockly.Python.INDENT + 'if service_result:\n'
  code = code + Blockly.Python.INDENT + Blockly.Python.INDENT + Blockly.Python.INDENT + 'result = true\n'
  code = code + Blockly.Python.INDENT + 'return result\n'
  code = Blockly.Python.scrub_(block, code);
  // Add % so as not to collide with helper functions in definitions list.
  Blockly.Python.definitions_['%' + funcName] = code;
  // code = code + Blockly.Python.INDENT + 'import base64\n'
  // code = code + Blockly.Python.INDENT + 'data = none\n'
  // code = code + Blockly.Python.INDENT + 'field = none\n\n'
  // code = code + Blockly.Python.INDENT + 'print("This Function was triggered by messageId {} published at {}".format(context.event_id, context.timestamp))\n\n'
  // code = code + Blockly.Python.INDENT + 'if "data" in event:\n'
  // code = code + Blockly.Python.INDENT + Blockly.Python.INDENT + 'data = base64.b64decode(event["data"]).decode("utf-8")\n\n'
  // code = code + Blockly.Python.INDENT + 'if ' + field +' in data:\n'
  // code = code + Blockly.Python.INDENT + Blockly.Python.INDENT + 'field = data[{'+ field +'}]\n'

  return null;
};

Blockly.Python['data_field'] = function(block) {
  var field = Blockly.Python.quote_(block.getFieldValue('column_name'));
  var code = 'def policy1(event, context):\n'
  code = code + Blockly.Python.INDENT + 'import base64\n'
  code = code + Blockly.Python.INDENT + 'data = none\n'
  code = code + Blockly.Python.INDENT + 'field = none\n\n'
  code = code + Blockly.Python.INDENT + 'print("This Function was triggered by messageId {} published at {}".format(context.event_id, context.timestamp))\n\n'
  code = code + Blockly.Python.INDENT + 'if "data" in event:\n'
  code = code + Blockly.Python.INDENT + Blockly.Python.INDENT + 'data = base64.b64decode(event["data"]).decode("utf-8")\n\n'
  code = code + Blockly.Python.INDENT + 'if ' + field +' in data:\n'
  code = code + Blockly.Python.INDENT + Blockly.Python.INDENT + 'field = data[{'+ field +'}]\n'

  return code;
};

Blockly.Python['discover_service'] = function(block) {
  var value_source = Blockly.Python.valueToCode(block, 'source', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = '"{}://{}-dot-{}.appspot.com".format("https",'+ value_source +', os.env("GAE_APPLICATION"))';
  code = Blockly.Python.scrub_(block, code);
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Python['service'] = function(block) {
  var value_url = Blockly.Python.valueToCode(block, 'url', Blockly.Python.ORDER_ATOMIC);
  var value_field_name = Blockly.Python.valueToCode(block, 'field_name', Blockly.Python.ORDER_ATOMIC);
  var value_field_value = Blockly.Python.valueToCode(block, 'field_value', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = '...';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};