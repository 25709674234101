'use strict';

goog.require('Blockly.Blocks');
goog.require('Blockly');
goog.require('Blockly.Msg');

Blockly.Msg.LOOKUP_FUNCTION_COMMENT = '\nLookup service\nParams:\n\tsource: string, set to the lookup data source\n\tfield: string, set to the field to lookup\nReturns:\n\tresult: boolean, true if found else false';

Blockly.defineBlocksWithJsonArray(
  [{
      type: 'data_item',
      message0: 'field %1',
      args0: [{
        type: 'input_dummy',
        name: 'INPUT'
      }],
      colour: 120,
      output: 'String',
      tooltip: 'Data Item',
      extensions: ['data_item_extension']
    },
    {
      type: 'lookup_data_item',
      message0: 'lookup field %1',
      args0: [{
        type: 'input_dummy',
        name: 'INPUT'
      }],
      colour: 120,
      output: 'String',
      tooltip: 'Lookup Data Item',
      extensions: ['lookup_data_item_extension']
    },
    {
      type: 'lookup_service',
      message0: 'lookup: %1 in: %2',
      args0: [{
          type: 'input_value',
          name: 'lookup_field'
        },
        {
          type: 'input_value',
          name: 'lookup_source'
        }
      ],
      inputsInline: true,
      output: 'Boolean',
      colour: 290,
      tooltip: 'Lookup service',
      helpUrl: ''
    }
  ]);

Blockly.Blocks['block_type'] = {
  init: function() {
    this.appendValueInput('lookup_field')
        .setCheck(null)
        .appendField('lookup: ');
    this.appendValueInput('lookup_source')
        .setCheck(null)
        .appendField('in: ');
    this.setInputsInline(true);
    this.setColour(290);
    this.setTooltip('Lookup service');
    this.setHelpUrl('');
    this.setOutput('Boolean');
    // if ((this.workspace.options.comments ||
    //     (this.workspace.options.parentWorkspace &&
    //       this.workspace.options.parentWorkspace.options.comments)) &&
    //   Blockly.Msg['PROCEDURES_DEFRETURN_COMMENT']) {
    //   // this.setCommentText(Blockly.Msg['PROCEDURES_DEFRETURN_COMMENT'])
    //   this.setCommentText(Blockly.Msg['LOOKUP_FUNCTION_COMMENT']);
    // }
  }
};

Blockly.Extensions.register('data_item_extension',
  function() {
    this.getInput('INPUT')
      .appendField(new Blockly.FieldDropdown(
        function() {
          var options = [];
          if (window.datasetResult) {
            window.datasetResult.properties.forEach(element => options.push([element.name, element.name]));
          } else {
            options.push(['loading...', 'loading...']);
          }
          return options;
        }), 'DATA');
  });
Blockly.Extensions.register('lookup_data_item_extension',
  function() {
    this.getInput('INPUT')
      .appendField(new Blockly.FieldDropdown(
        function() {
          var options = [];
          if (window.overrideDatasetResult) {
            window.overrideDatasetResult.properties.forEach(element => options.push([overrideDatasetResult.name + '.' + element.name, element.name]));
          } else {
            options.push(['loading...', 'loading...']);
          }
          return options;
        }), 'DATA');
  });

Blockly.Blocks['lookup_function'] = {
  /**
   * Block for defining a lookup function with return value as result.
   * @this Blockly.Block
   */
  init: function() {
    var nameField = new Blockly.FieldTextInput('lookup',
      Blockly.Procedures.rename);
    nameField.setSpellcheck(false);
    var sourceField = new Blockly.FieldVariable("source")
    this.appendDummyInput()
      .appendField(Blockly.Msg['PROCEDURES_DEFRETURN_TITLE'])
      .appendField(nameField, 'NAME');
    // .appendField('', 'PARAMS')
    // .appendField("Source: ")
    // .appendField(new Blockly.FieldTextInput(''), "PARAMS");
    this.appendValueInput('RETURN')
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(Blockly.Msg['PROCEDURES_DEFRETURN_RETURN']);
    this.setMutator(new Blockly.Mutator(['procedures_mutatorarg']));
    if ((this.workspace.options.comments ||
        (this.workspace.options.parentWorkspace &&
          this.workspace.options.parentWorkspace.options.comments)) &&
      Blockly.Msg['PROCEDURES_DEFRETURN_COMMENT']) {
      // this.setCommentText(Blockly.Msg['PROCEDURES_DEFRETURN_COMMENT'])
      this.setCommentText(Blockly.Msg['LOOKUP_FUNCTION_COMMENT']);
    }
    this.setStyle('procedure_blocks');
    this.setTooltip(Blockly.Msg['PROCEDURES_DEFRETURN_TOOLTIP']);
    this.setHelpUrl(Blockly.Msg['PROCEDURES_DEFRETURN_HELPURL']);
    this.arguments_ = [];
    this.argumentVarModels_ = [];
    this.setStatements_(true);
    this.statementConnection_ = null;
    this.setA
  },
  setStatements_: Blockly.Blocks['procedures_defnoreturn'].setStatements_,
  updateParams_: Blockly.Blocks['procedures_defnoreturn'].updateParams_,
  mutationToDom: Blockly.Blocks['procedures_defnoreturn'].mutationToDom,
  domToMutation: Blockly.Blocks['procedures_defnoreturn'].domToMutation,
  decompose: Blockly.Blocks['procedures_defnoreturn'].decompose,
  compose: Blockly.Blocks['procedures_defnoreturn'].compose,
  /**
   * Return the signature of this procedure definition.
   * @return {!Array} Tuple containing three elements:
   *     - the name of the defined procedure,
   *     - a list of all its arguments,
   *     - that it DOES have a return value.
   * @this Blockly.Block
   */
  getProcedureDef: function() {
    return [this.getFieldValue('NAME'), this.arguments_, true];
  },
  getVars: Blockly.Blocks['procedures_defnoreturn'].getVars,
  getVarModels: Blockly.Blocks['procedures_defnoreturn'].getVarModels,
  renameVarById: Blockly.Blocks['procedures_defnoreturn'].renameVarById,
  updateVarName: Blockly.Blocks['procedures_defnoreturn'].updateVarName,
  displayRenamedVar_: Blockly.Blocks['procedures_defnoreturn'].displayRenamedVar_,
  customContextMenu: Blockly.Blocks['procedures_defnoreturn'].customContextMenu,
  callType_: 'procedures_callreturn'
};


Blockly.Blocks['discover_service'] = {
  init: function() {
    this.appendValueInput("source")
        .setCheck(null)
        .appendField("discover service for:");
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(290);
    this.setTooltip("discover service");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['service'] = {
  init: function() {
    this.appendValueInput("url")
        .setCheck("String")
        .appendField("url");
    this.appendValueInput("field_name")
        .setCheck("String")
        .appendField("field name");
    this.appendValueInput("field_value")
        .setCheck(null)
        .appendField("field_value");
    this.setOutput(true, null);
    this.setColour(230);
 this.setTooltip("get by field api");
 this.setHelpUrl("");
  }
};